<template>
    <div class="main-container">
      
        <!-- 왼쪽 수정/삭제 폼 -->
        <div class="left-panel">
            <h5 class="pageTitle">기수 수정/삭제</h5>
            <div class="form-container">
                <div class="form-group">
                    <label class="form-label" for="batch_name">기수 명</label>
                    <input v-model="form.batch_name" type="text" class="input-box" placeholder="기수 명을 입력하세요" readonly />
                </div>
                <div class="form-group">
                    <label for="selected_users">이름</label>
                    <textarea v-model="selectedNames" class="user-list" readonly></textarea>
                </div>
                <div class="button-container">
                    <button class="button-update" @click="updateBatch">수정</button>
                    <button class="button-delete" @click="deleteBatch">삭제</button>
                    <button class="button-list" @click="goToList">목록</button>
                </div>
            </div>
        </div>

        <!-- 오른쪽 검색 및 사용자 목록 -->
        <div class="right-panel">
            <div class="search-container">
                <input v-model="searchQuery" type="text" placeholder="검색어를 입력하세요." class="search-input" />
            </div>
            
            <div class="table-container">
                <table>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>기수명</th>
                            <th>이름</th>
                            <th>전화번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="paginatedUsers.length === 0">
                            <td colspan="4">데이터가 없습니다.</td>
                        </tr>
                        <tr v-for="(user, index) in paginatedUsers" :key="user.uuid" class="clickable-row" @click="toggleUserSelection(user)">
                            <td>{{ index + 1 + (currentPage - 1) * perPage }}</td>
                            <td>{{ user.batch_name }}</td>
                            <td>{{ user.name }}</td>
                            <td>{{ user.phone }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="pagination">
                <button class="pagination-button" @click="prevPage" :disabled="currentPage === 1">Prev</button>
                <span>Page {{ currentPage }} of {{ totalPages }}</span>
                <button class="pagination-button" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
            </div>
        </div>
    </div>
</template>


  <script>
  import axios from "@/axios";
  import Swal from "sweetalert2";

  export default {
    data() {
      return {
        form: {
          batch_name: "",
          users: []
        },
        users: [],
        selectedUsers: [],
        selectedUserIds:[],
        calendarIDs:[],        
        currentBatchName: "",
        currentPage: 1,
        perPage: 14,
        batchNameExists: false,
        searchQuery: ""
      };
    },
    watch: {
      searchQuery() {
        this.currentPage = 1;
      }
    },
    computed: {
      selectedNames() {
        return this.selectedUsers.map(user => user.name).join(", ");
      },
      totalPages() {
        return Math.ceil(this.filteredUsers.length / this.perPage);
      },
      paginatedUsers() {
        const start = (this.currentPage - 1) * this.perPage;
        return this.filteredUsers.slice(start, start + this.perPage);
      },
      filteredUsers() {
        return this.users.filter(user => 
          (user.name ? user.name.toLowerCase() : '').includes(this.searchQuery.toLowerCase()) || 
          (user.phone ? user.phone.toLowerCase() : '').includes(this.searchQuery.toLowerCase()) ||
          (user.batch_name ? user.batch_name.toLowerCase() : '').includes(this.searchQuery.toLowerCase())
        );
      }
    },

    mounted() {
      //this.fetchUsers();
      this.fetchUsers().then(() => {
          const batchName = this.$route.query.batch_name;
         // batchName이 유효하지 않으면 비워둠
          if (typeof batchName === 'string' && batchName.trim() !== '' && batchName !== '[object PointerEvent]') {
              this.form.batch_name = batchName;
              this.selectedUsers = this.users.filter(user => user.batch_name === batchName);
          } else {
              this.form.batch_name = '';
              this.selectedUsers = [];
          }
      });
    },
    methods: {
      async fetchUsers() {
        try {
          const token = this.getToken();
          const response = await axios.get("/users/allForBatch", {
            headers: { Authorization: `Bearer ${token}` }
          });
          this.users = response.data.map(user => ({
              batch_name: user.batch,
              name: user.name,
              phone: user.phone,
              uuid: user.uuid
          }));
        } catch (error) {
          console.error("사용자 목록 불러오기 실패:", error);
        }
      },
      toggleUserSelection(user) {
        const currentBatchName = this.form.batch_name;

        // 1. 초기 상태에서 기수가 없는 사람은 선택 불가
        if (!currentBatchName && !user.batch_name  ) {
            Swal.fire('경고', '기수명이 설정되지 않은 상태에서는 기수가 없는 사용자를 선택할 수 없습니다.', 'warning');
            return;
        }

        // 2. 다른 기수를 선택했을 때, 기수명 및 멤버 목록 업데이트
        if (user.batch_name && user.batch_name.trim() !== currentBatchName) {
            this.form.batch_name = user.batch_name;
            this.selectedUsers = this.users.filter(u => u.batch_name === user.batch_name);
            return;
        }

          // 3. 선택된 유저가 현재 기수에 속한 경우 (삭제)
          const index = this.selectedUsers.findIndex(u => u.uuid === user.uuid);
          if (index !== -1) {
              // 기존에 기수가 있던 사람을 삭제할 때 경고 표시
              if (user.batch_name) {
                  Swal.fire({
                      title: '경고',
                      text: '기존 일정과 SMS에 영향을 미칩니다. 삭제하시겠습니까?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonText: '예',
                      cancelButtonText: '아니오'
                  }).then((result) => {
                      if (result.isConfirmed) {
                          this.selectedUsers.splice(index, 1);
                      }
                  });
              } else {
                  this.selectedUsers.splice(index, 1);
              }
              return;
          }

          // 4. 새로운 유저 추가 (기수가 없는 사람만 추가 가능)
          if (!user.batch_name) {
              this.selectedUsers.push(user);
          }
      },


      prevPage() {
        if (this.currentPage > 1) this.currentPage--;
      },
      nextPage() {
        if (this.currentPage < this.totalPages) this.currentPage++;
      },
      getToken() {
        const tokenData = JSON.parse(sessionStorage.getItem("token"));
        return tokenData ? tokenData.access_token : "";
      },



      // async deleteBatch() {
      //   console.log("삭제 기능 호출됨");
      //   const currentBatchName = this.form.batch_name;

      //   if (!currentBatchName) {
      //       Swal.fire('경고', '기수명이 설정되지 않았습니다.', 'warning');
      //       return;
      //   }

      //   try {
      //       const token = this.getToken();

      //       await Swal.fire({
      //           title: '경고',
      //           text: '기수 삭제 시 일정 및 SMS에 영향을 미칩니다. 진행하시겠습니까?',
      //           icon: 'warning',
      //           showCancelButton: true,
      //           confirmButtonText: '예',
      //           cancelButtonText: '아니오'
      //       }).then(async (result) => {
      //           if (result.isConfirmed) {
      //               const response = await axios.post('/users/removeBatch', {
      //                   batch_name: currentBatchName
      //               }, {
      //                   headers: { Authorization: `Bearer ${token}` }
      //               });

      //               console.log('Batch Remove Response:', response.data);

      //               Swal.fire('성공', '기수 정보가 초기화되었습니다.', 'success');
      //               this.goToList();
      //           }
      //       });
      //   } catch (error) {
      //       console.error("기수 삭제 중 오류 발생:", error);
      //       Swal.fire('오류', '서버와 통신 중 오류가 발생했습니다.', 'error');
      //   }
      // },

      async deleteBatch() {
          console.log("삭제 기능 호출됨");
          this.currentBatchName = this.form.batch_name
          
        
          if (!this.currentBatchName) {
              Swal.fire('경고', '기수명이 설정되지 않았습니다.', 'warning');
              return;
          }

          let calendarCount = 0;
          let smsCount = 0;

          try {
              const token = this.getToken();

              await Swal.fire({
                  title: '경고',
                  text: '기수 삭제 시 일정 및 SMS에 영향을 미칩니다. 진행하시겠습니까?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: '예',
                  cancelButtonText: '아니오'
              }).then(async (result) => {
                  if (result.isConfirmed) {
                      // 1. 기수 관련 일정 확인 및 발송일이 지나지 않은 일정 필터링
                      calendarCount = await this.checkCalendarData();
                      
                      if (calendarCount > 0) {
                          
                          //await Swal.fire('알림', `${calendarCount}개의 관련 일정을 찾았습니다.`, 'info');

                          // 2. SMS 삭제 처리
                          smsCount = await this.checkSmsData();
                          if (smsCount > 0) {
                              //await Swal.fire('알림', `${smsCount}개의 SMS 데이터를 찾았습니다.`, 'info');
                              const smsDeleted = await this.deleteSmsData();
                              if (!smsDeleted) {
                                  // await Swal.fire('오류', 'SMS 삭제 중 오류가 발생했습니다.', 'error');
                                  // return;
                                  throw new Error('SMS 삭제 중 오류가 발생했습니다.');
                              } 
                              // else {
                              //     await Swal.fire('성공', 'SMS 데이터가 삭제되었습니다.', 'success');
                              // }
                          }

                          // 3. 일정에서 SMS관련부분  초기화 API 호출
                          await axios.post('/calendar/reset_batch', {
                              batch_name: this.currentBatchName
                          }, {
                              headers: { Authorization: `Bearer ${token}` }
                          });
                      }

                      // 4. 사용자 기수 초기화
                      await axios.post('/users/removeBatch', {
                          batch_name: this.currentBatchName
                      }, {
                          headers: { Authorization: `Bearer ${token}` }
                      });

                     //Swal.fire('성공', '기수 및 관련 일정과 SMS 정보가 초기화되었습니다.', 'success');
                     // 최종 성공 메시지 (단계별 메시지 생략)
                      await Swal.fire(
                          '성공',
                          `일정: ${calendarCount}개, SMS: ${smsCount}개를 삭제했으며, 기수 및 관련 데이터를 초기화했습니다.`,
                          'success'
                      );
                      this.goToList();
                  }
              });
          } catch (error) {
              console.error("기수 삭제 중 오류 발생:", error);
              await Swal.fire('오류', `${error.message}`, 'error');
          }
      },



      goToList() {
        this.$router.push("/batch-read");
      },

      

      async updateBatch() {
          console.log("기수 수정 기능 호출됨");

          if (!this.validateBatchData()) {
              return;
          }
  
          let calendarCount =0;
          let smsCount =0;

          try{
              if (this.selectedUserIds.length > 0) {
                // 1. 일정 데이터 확인
                  calendarCount = await this.checkCalendarData();

                  if (calendarCount > 0) 
                  {
                      //await Swal.fire('알림', `${calendarCount}개의 관련 일정을 찾았습니다.`, 'info');

                      // 2. SMS 데이터 처리
                      smsCount = await this.checkSmsData();
                      if (smsCount > 0) {
                          //await Swal.fire('알림', `${smsCount}개의 SMS 데이터를 찾았습니다.`, 'info');

                          const smsDeleted = await this.deleteSmsData();
                          if (!smsDeleted) {
                              // await Swal.fire('오류', 'SMS 삭제 중 오류가 발생했습니다.', 'error');
                              // return;
                              throw new Error('SMS 삭제 중 오류가 발생했습니다.');
                          } else {
                              //await Swal.fire('성공', 'SMS 데이터가 삭제되었습니다.', 'success');

                              // 새로 SMS 데이터를 생성
                              const smsCreated = await this.createSmsData();
                              if (!smsCreated) {
                                  // await Swal.fire('오류', 'SMS 생성 중 오류가 발생했습니다.', 'error');
                                  // return;
                                  throw new Error('SMS 생성 중 오류가 발생했습니다.');
                              } 
                              // else {
                              //     await Swal.fire('성공', 'SMS 데이터가 성공적으로 생성되었습니다.', 'success');
                              // }
                          }
                      }
                  }
                  // 3. 최종 기수 업데이트
                  await this.updateOnlyBatch();

                  //await Swal.fire('성공', '기수 수정이 완료되었습니다.', 'success');
                  await Swal.fire(
                      '성공',
                      `일정: ${calendarCount}개, SMS: ${smsCount}개를 찾았고, 
                      이에 대한 업데이트 완료 및 기수정보를 업데이트했습니다.`,
                      'success'
                  );                                        
                  
              } else {   //if (this.selectedUserIds.length <= 0)
                  await Swal.fire('정보', '변경된 내용이 없습니다.', 'info');
              }
         }catch (error) {
              // 에러 메시지를 실패 단계별로 표시
              await Swal.fire('오류', `${error.message}`, 'error');
          }


      },
      validateBatchData() {
          this.currentBatchName = this.form.batch_name.trim();
          this.selectedUserIds = this.selectedUsers.map(user => user.uuid);

          if (!this.currentBatchName) {
              Swal.fire('경고', '기수명이 설정되지 않았습니다.', 'warning');
              return false;
          }

          console.log('Selected Users:', this.selectedUserIds);
          return true;
      },

      //* 여기서 calendarID는 SMS데이터가 있는 경우만 찾아온다. 즉
      //  일정에 참석자가 등록되어있어도 sms메세지와 상관 없으면  기수수정시 기수만 적용된다. 
      async checkCalendarData() {
          try {
              
              const token = this.getToken();
              const response = await axios.get(`/calendar/get-calendar-ids/${this.currentBatchName}`, {
                  headers: { Authorization: `Bearer ${token}` }
              });

              console.log("API Response:", response.data);              

              this.calendarIDs = response.data.calendarIds || [];
              console.log("Calendar IDs:", this.calendarIDs);
            
              return this.calendarIDs.length;

          } catch (error) {
              console.error("일정 데이터 확인 중 오류:", error);
              return 0;
          }
      },

      async checkSmsData() {
          try {
              const token = this.getToken();
              let totalSmsCount = 0;

              for (const calendarId of this.calendarIDs) {
                  const response = await axios.get(`/sms-main/find_by_calendar/${calendarId}`, {
                      headers: { Authorization: `Bearer ${token}` }
                  });
                  totalSmsCount += response.data.length;
              }

              console.log("총 SMS 데이터 개수:", totalSmsCount);
              return totalSmsCount;
          } catch (error) {
              console.error("SMS 데이터 확인 중 오류:", error);
              return 0;
          }
      },

      async deleteSmsData() {
          try {
              const token = this.getToken();

              for (const calendarId of this.calendarIDs) {
                  const response = await axios.delete(`/sms-main/delete_messages/${calendarId}`, {
                      headers: { Authorization: `Bearer ${token}` }
                  });
                  console.log(`SMS 삭제 결과 (Calendar ID: ${calendarId}):`, response.data);
              }

              return true;
          } catch (error) {
              console.error("SMS 삭제 중 오류:", error);
              return false;
          }
      },

      async createSmsData() {
          console.log("SMS 데이터 생성 기능 호출됨");
          const token = this.getToken();
          try {
              if (!this.calendarIDs || this.calendarIDs.length === 0) {
                  console.warn("일정 ID를 찾을 수 없습니다.");
                  return false;
              }

              const calendarData = await Promise.all(
                  this.calendarIDs.map(calendarId => axios.get(`/calendar/${calendarId}`, {
                      headers: { Authorization: `Bearer ${token}` }
                  }))
              );

              const smsData = [];

              for (const calendar of calendarData) {
                  const { uuid, send_time, templateID } = calendar.data;
                  let msgTemplate = '';

                  if (templateID) {
                      try {
                          const templateResponse = await axios.get(`/sms-template/read_formed_messages`, {
                              headers: { Authorization: `Bearer ${token}` }
                          });
                          const template = templateResponse.data.find(item => item.uuid === templateID);
                          msgTemplate = template ? template.msg_contents : '';
                      } catch (templateError) {
                          console.error('템플릿 조회 오류:', templateError);
                      }
                  }

                  this.selectedUsers.forEach(user => {
                      // 이름이 3글자를 초과하면 잘라냅니다
                      const shortName = user.name.length > 3 ? user.name.substring(0, 3) : user.name;
                      smsData.push({
                          cmid: `${Date.now()}`,
                          calendar_ID: uuid,
                          msg_type: 0,
                          status: 0,
                          request_time: new Date().toISOString(),
                          send_time: new Date(send_time).toISOString(),
                          dest_phone: user.phone,
                          send_phone: process.env.VUE_APP_SENDER_NUMBER,
                          msg_body: msgTemplate.includes('${name}')
                              ? msgTemplate.replace('${name}', shortName)
                              : msgTemplate || `안녕하세요, ${shortName}님. 기수 변경 안내드립니다.`
                      });
                  });
              }

              await Promise.all(
                  smsData.map(data =>
                      axios.post('/sms-main/create_message?cmidPrefix=C', data, {
                          headers: { Authorization: `Bearer ${token}` }
                      })
                  )
              );

              return true;
          } catch (error) {
              console.error('SMS 생성 중 오류:', error);
              return false;
          }
      },

      async updateOnlyBatch()
      {
        const currentBatchName = this.form.batch_name.trim();
        const selectedUserIds = this.selectedUsers.map(user => user.uuid);

        console.log("Current Batch Name:", currentBatchName);
        console.log("Selected User IDs:", selectedUserIds);

        const existingBatchUsers = this.users.filter(user => user.batch_name === this.currentBatchName).map(user => user.uuid);

        const usersToRemove = existingBatchUsers.filter(uuid => !this.selectedUserIds.includes(uuid));
        const usersToAdd = selectedUserIds.filter(uuid => !existingBatchUsers.includes(uuid));

        try {
            const token = this.getToken();

            console.log('usersToRemove:', JSON.stringify(usersToRemove, null, 2));


            // 1. 기존 사용자들에서 삭제된 사용자들 처리
            if (usersToRemove.length > 0) {
              console.log("Removing users from batch...");
                const removeResponse=await axios.post('/users/removeFromBatch', {
                    user_ids: usersToRemove
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                console.log('Remove Response:', removeResponse.data);
            }

            // 2. 새로운 사용자들 추가
            if (usersToAdd.length > 0) {
              console.log("Adding users to batch...");
                const addResponse=await axios.post('/users/addToBatch', {
                    batch_name: this.currentBatchName,
                    user_ids: usersToAdd
                }, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                console.log('Add Response:', addResponse.data);
            }

            Swal.fire('성공', '기수 수정이 완료되었습니다.', 'success');
            this.goToList();
        } catch (error) {
            console.error("기수 수정 중 오류 발생:", error);
            Swal.fire('오류', '서버와 통신 중 오류가 발생했습니다.', 'error');
        }
      },

    },
   
  };
  </script>


<style scoped>
.main-container {
    padding: 20px;
    display: flex;
    gap: 20px;
    height: 100vh;
}
/* .main-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.left-panel {
    flex: 1;
    max-width: 40%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.right-panel {
    flex: 1;
    max-width: 60%;
    display: flex; 
    flex-direction: column;    
    gap: 10px;
}

.pageTitle {
  font-weight: bold;
  margin-bottom: 40px;
  text-align: left;
  width: 100%;
}
/* .form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 70%;
  max-width: 300px;
} */
.form-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 100%;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}


.input-box {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 300px; /* 기수명 입력 상자 길이 조정 */
  width: 100%;
 }

.user-list-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.user-list {
  flex: 1;
  padding: 8px;
  border: 1px solid rgba(130, 128, 128, 0.26);
  border-radius: 4px;
  min-height: 200px;
  max-width: 300px; /* 사용자 목록 크기 고정 */
  width: 100%;
}
.btn-select {
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap; /* 버튼 크기 고정 */
  align-self: flex-start; /* 버튼을 상단 정렬 */
}
.btn-select:hover {
  background-color: #0056b3;
}
/* .user-selection-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
} */
.button-container {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
}
.button-update {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  margin-right: 10px;
}
.disabled-button {
  background-color: #ccc !important;
  cursor: not-allowed !important;
  color: #666 !important;
}
.button-delete {
  background-color: #f44336;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  margin-right: 10px;
} 
.button-list {
  background-color: #ff9800;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}


.search-container {
    padding: 10px 0;
    margin-left: 5%;
    margin-right: 5%;
}

.search-input{
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 600px; /* 기수명 입력 상자 길이 조정 */
  width: 100%;
  margin-top: 80px;
}
.table-container {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 800px;
    margin-left: 5%;
    margin-right: 5%;
    width: 90%;
    /* box-sizing: border-box;  */
}
/* .user-table-container {
  margin-top: 20px;
  width: 100%;
} */

 
  .selected {
  background-color: #e6f2ff;
  }

  table {
  width: 100%;
  border-collapse: collapse;
  /* margin: auto; */
  margin-top: 20px;
  text-align: center;
  }
  th, td {
    border: 1px solid #cbc8c8;
    padding: 8px;
    text-align: center;
  }
  th {
    background-color: #e3e4eb;
    height: 55px;
  }
  tr {
    height: 50px;
    cursor: pointer;
  }
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.pagination-button {
  padding: 5px 10px;
  margin: 0 5px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.pagination-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}
.error-text {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
</style>
